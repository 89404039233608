<template>
  <v-app-bar
    class="header title-color shrink"
    height="70"
    app
  >
    <v-layout
      align-center
    >
      <v-flex
        align-center
      >
        <div
          class="header__logo-alignment"
        >
          <v-app-bar-title class="header__logo">
            <img
              class="header__logo"
              src="@/assets/amparo-grey-icon.svg"
              @click="toggleIsMenuOpen"
            >
          </v-app-bar-title>
          <v-flex
            ml-4
            class="header__workspace"
          >
            <v-select
              v-model="selectedWorkSpaceId"
              class="header__workspace-select"
              :items="userWorkSpaces"
              :disabled="isImpersonating"
              item-text="name"
              item-value="id"
              hide-details
              height="15px"
              solo
              flat
              placeholder="Ambiente"
              data-testid="header__workspace--select"
              @change="switchWorkSpace(selectedWorkSpaceId)"
            />
          </v-flex>
        </div>
        <v-flex
          v-if="userHasAccessToListPatient"
          align-center
          ml-12
          class="hidden-xs-only header__handle-md-breakpoint"
        >
          <search-patient
            ref="searchInput"
            class="search-bar__input-field"
            prepend-icon="mdi-magnify"
            solo
            flat
            clearable
            hide-details
            @input="selectedPatient = $event"
          />
        </v-flex>
      </v-flex>

      <v-flex
        shrink
      >
        <v-toolbar-items
          class="hidden-sm-and-down"
        >
          <v-layout
            ml-12
            class="toolbar__item a-link header__handle-md-breakpoint"
          >
            <router-link
              class="toolbar__item a-link header__handle-md-breakpoint"
              to="/"
              ml-12
            >
              <span>Início</span>
            </router-link>
          </v-layout>
          <v-layout
            v-if="shouldShowAgendaButton"
            ml-10
            class="header__handle-md-breakpoint"
          >
            <router-link
              class="toolbar__item a-link"
              to="/agenda"
              exact
            >
              <span>Agenda</span>
            </router-link>
          </v-layout>
          <v-layout
            v-if="!featureFlagPatient"
            ml-10
            class="header__handle-md-breakpoint"
          >
            <router-link
              class="toolbar__item a-link"
              to="/patients-list"
              exact
            >
              <span>Pacientes</span>
            </router-link>
          </v-layout>
          <v-layout
            v-if="userHasAccessToSchedule"
            ml-10
            class="header__handle-md-breakpoint"
          >
            <router-link
              class="toolbar__item a-link"
              to="/schedule/list"
              exact
            >
              <span>Tarefas</span>
            </router-link>
          </v-layout>
          <v-layout
            v-if="userHasAccessToDashboards"
            ml-10
            class="header__handle-md-breakpoint"
          >
            <reports-dropdown class="toolbar__item" />
          </v-layout>
          <v-layout
            ml-10
            mr-4
            class="header__handle-md-breakpoint"
          >
            <user-dropdown class="toolbar__item" />
          </v-layout>
          <v-layout
            ml-2
          >
            <v-icon
              @click="$emit('openMenuSupport', true)"
            >
              mdi-help-circle-outline
            </v-icon>
          </v-layout>
        </v-toolbar-items>
      </v-flex>
    </v-layout>
    <show-announcement />
  </v-app-bar>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import {
  path,
  map,
  prop,
  any,
  pickAll,
  values,
  identity,
  isNil,
} from 'ramda'
import SearchPatient from '@/components/Common/SearchPatient'
import { WORK_SPACE } from 'amparo-enums'
import UserDropdown from './UserDropdown'
import ReportsDropdown from './ReportsDropdown'

export default {
  name: 'AppHeader',
  components: {
    SearchPatient,
    UserDropdown,
    ReportsDropdown,
    ShowAnnouncement: () => import('./ShowAnnouncement'),
  },
  props: {
    impersonatedWorkSpace: {
      type: Object,
      require: false,
      default: null,
    },
  },
  data() {
    return {
      selectedWorkSpaceId: null,
      featureFlagPatient: true,
      selectedPatient: null,
      isMenuOpen: false,
    }
  },
  computed: {
    ...mapGetters({
      user: 'authentication/user',
      userHasAccessToFunctionality: 'authentication/userHasAccessToFunctionality',
    }),
    isLabinWorkSpace() {
      return this.user.workSpaceId === WORK_SPACE.sabinColeta
    },
    userWorkSpaces() {
      return !this.isImpersonating
        ? map(prop('workSpace'), path(['userWorkSpaces'], this.user) || [{}])
        : [this.impersonatedWorkSpace]
    },
    isImpersonating() {
      return !isNil(this.impersonatedWorkSpace)
    },
    professionalId() {
      return path(['professionalId'], this.user)
    },
    userHasAccessToAgenda() {
      return this.userHasAccessToFunctionality.agenda
    },
    userHasAccessToListPatient() {
      return this.userHasAccessToFunctionality.listPatient
    },
    userHasAccessToSchedule() {
      return this.userHasAccessToFunctionality.scheduleList && this.professionalId
    },
    userHasAccessToDashboards() {
      const dashboardPermissions = values(pickAll([
        'attendanceReport',
        'populationPanel',
        'operationalPanel',
        'patientJourneyPanel',
        'patientRadar',
      ], this.userHasAccessToFunctionality))

      return any(identity, dashboardPermissions)
    },
    shouldShowAgendaButton() {
      return this.userHasAccessToAgenda && !this.isLabinWorkSpace
    },
  },
  watch: {
    selectedPatient(patient) {
      if (patient) this.$router.push(`/patient/${patient.id}`)
      this.$refs.searchInput.selectedPatient = ''
      this.$refs.searchInput.patientList = []
    },
    isImpersonating(impersonating) {
      if (impersonating) this.selectedWorkSpaceId = prop('id', this.impersonatedWorkSpace)
    },
  },
  mounted() {
    this.selectedWorkSpaceId = path(['workSpaceId'], this.user)
  },
  methods: {
    ...mapActions({
      switchUserWorkSpace: 'authentication/switchUserWorkSpace',
      getImpersonation: 'advanced/getImpersonation',
    }),
    switchWorkSpace(workSpaceId) {
      this.$router.push('/')
      this.switchUserWorkSpace(workSpaceId)
    },
    toggleIsMenuOpen() {
      this.isMenuOpen = !this.isMenuOpen
      this.$emit('toggleMenu', this.isMenuOpen)
    },
  },
}
</script>

<style lang="stylus" scoped>

.header
  background-color #fff !important
  z-index 21 !important

.header__logo
  cursor pointer
  height 35px

@media only screen and (min-width: 960px)
  @media only screen and (max-width: 1264px)
    .header__handle-md-breakpoint
      margin-left 20px !important
      > a
        margin-left 0px !important

.header__workspace
  cursor pointer
  height 35px
  width  190px

.header__workspace-select
  cursor pointer
  margin-top -6px !important

.header__logo-alignment
  display flex
  align-content center

.toolbar__item
  margin auto
  cursor pointer
  font-size 16px
  line-height 1.25

.search-bar__input-field >>> .v-input__slot
  padding-left: 0 !important

</style>
