<template>
  <div>
    <exam-collection-list-filters
      @update:filters="handleFiltersUpdate"
    />

    <v-layout
      v-if="invalidFilters"
      class="mt-8 mx-11"
    >
      <h2 class="info-text font-weight-lighter">
        Preencha os filtros obrigatórios.
      </h2>
    </v-layout>
    <div
      v-else
      class="mt-8 mx-11"
    >
      <v-data-table
        class="exam-collection-table table--striped"
        :headers="examCollectionHeaders"
        :items="examCollections"
        :loading="isLoading"
        no-data-text="Sem exames domiciliares para exibir"
        hide-default-footer
      >
        <template v-slot:item="{ item }">
          <tr>
            <td>
              <span class="dark-gray-text font-weight-medium">
                {{ item.appointment.date | formatDate }}<br>
                {{ item.appointment.startTime | removeSecondsFromTime }} -
                {{ item.appointment.endTime | removeSecondsFromTime | addOneMinute }}
              </span>
            </td>
            <td>
              <span class="dark-gray-text font-weight-medium">
                {{ formatStatus(item.appointment.status) }}
              </span>
            </td>
            <td>
              <span class="tw-font-medium">
                <router-link :to="{ name: 'patientProfile', params: { id: item.patient.id }}">
                  {{ item.patient.name }}
                </router-link>
              </span>
              <v-flex class="text-subtitle-3 amparo-gray--text font-weight-medium">
                {{ formatBiologicalSex(item.patient.biologicalSex) }}
                | {{ item.patient.birthDate | formatAge }}
                | {{ item.patientHeight | formatHeight }}
                | {{ item.patientWeight | formatWeight }}
              </v-flex>
            </td>
            <td class="tw-text-gray-dark tw-font-medium">
              <span class="tw-flex tw-items-center">
                {{ item.patient.healthInsurancePlanNumber }}

                <v-tooltip
                  v-model="showCopyTooltipMap[item.id]"
                  bottom
                >
                  <template #activator="{ on }">
                    <v-btn
                      class="tw-ml-2"
                      small
                      icon
                      open-on-hover="false"
                      v-on="{ on }"
                      @click="copyToClipboard(item.patient.healthInsurancePlanNumber, item.id)"
                    >
                      <v-icon
                        size="18"
                      >
                        mdi-content-copy
                      </v-icon>
                    </v-btn>
                  </template>
                  Copiado!
                </v-tooltip>
              </span>
            </td>
            <td>
              <span
                v-if="item.examRequestAttachmentIds?.length > 0"
                class="font-weight-medium"
              >
                <v-btn
                  text
                  color="info"
                  class="mx-n4 font-weight-bold text-capitalize text-decoration-underline"
                  @click="openAttachmentsDialog(item)"
                >
                  <span>
                    Ver arquivos
                  </span>
                </v-btn>
              </span>
              <span v-else>
                -
              </span>
            </td>
            <td>
              <div v-if="item.professional?.name">
                <span class="dark-gray-text font-weight-medium">
                  {{ item.professional.name }}
                </span>
              </div>
              <div v-else-if="!isActionDisabled(item)">
                <v-btn
                  text
                  color="primary"
                  class="mx-n4 font-weight-bold"
                  @click="setAndOpenEditDialog(item, 'assign')"
                >
                  <v-icon left>
                    mdi-plus
                  </v-icon>
                  <span>
                    Definir executante
                  </span>
                </v-btn>
              </div>
              <div v-else>
                -
              </div>
            </td>
            <td class="tw-text-gray-dark tw-font-medium tw-max-w-md">
              {{ item.appointment.notes || '-' }}
            </td>
            <td>
              <v-flex class="align-center justify-space-around">
                <v-tooltip bottom>
                  <template #activator="{ on, attrs }">
                    <v-btn
                      icon
                      fab
                      small
                      :disabled="isActionDisabled(item)"
                      v-bind="attrs"
                      v-on="on"
                      @click="setAndOpenEditDialog(item, 'edit')"
                    >
                      <v-icon>
                        mdi-pencil
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>Editar informações</span>
                </v-tooltip>

                <!-- <v-tooltip bottom>
                  <template #activator="{ on, attrs }">
                    <v-btn
                      icon
                      fab
                      small
                      :disabled="isActionDisabled(item)"
                      v-bind="attrs"
                      v-on="on"
                      @click="rescheduleAppointment(item)"
                    >
                      <v-icon>
                        mdi-calendar-edit
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>Reagendar</span>
                </v-tooltip> -->

                <v-tooltip bottom>
                  <template #activator="{ on, attrs }">
                    <v-btn
                      icon
                      fab
                      small
                      :disabled="isActionDisabled(item)"
                      v-bind="attrs"
                      v-on="on"
                      @click="setAndOpenCancelAppointmentDialog(item)"
                    >
                      <v-icon color="danger">
                        mdi-delete
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>Cancelar agendamento</span>
                </v-tooltip>
              </v-flex>
            </td>
          </tr>
        </template>
      </v-data-table>
    </div>

    <list-attachment-dialog
      v-if="selectedAttachmentsData.patient"
      :show-dialog="showListAttachmentDialog"
      :patient="selectedAttachmentsData.patient"
      :attachments="selectedAttachmentsData.attachments"
      :limited-list="false"
      :update-data="attachmentsUpdateData"
      type="general"
      @update:showDialog="toggleShowListAttachmentDialog"
    />

    <exam-collection-update-dialog
      v-if="updateDialogData.examCollectionData"
      :show-dialog="updateDialogData.showDialog"
      :title="updateDialogData.title"
      :field-names="updateDialogData.fieldNames"
      :exam-collection-data="updateDialogData.examCollectionData"
      @update:showDialog="toggleShowUpdateDialog"
      @update:examCollectionUpdated="handleExamCollectionUpdate"
    />

    <exam-collection-cancel-dialog
      v-if="cancelDialogData.examCollectionData"
      :show-dialog="cancelDialogData.showDialog"
      :exam-collection-data="cancelDialogData.examCollectionData"
      @update:showDialog="toggleShowCancelAppointmentDialog"
      @update:examCollectionUpdated="handleExamCollectionUpdate"
    />
  </div>
</template>

<script>
import { APPOINTMENT } from 'amparo-enums'
import { pluck } from 'ramda'
import ListAttachmentDialog from '@/components/Common/ListAttachmentDialog'
import ExamCollectionListFilters from './ExamCollectionListFilters'
import ExamCollectionUpdateDialog from './ExamCollectionUpdateDialog'
import ExamCollectionCancelDialog from './ExamCollectionCancelDialog'

const { mapGetters, mapActions } = require('vuex')

export default {
  name: 'ExamCollectionListContainer',
  components: {
    ExamCollectionListFilters,
    ExamCollectionUpdateDialog,
    ExamCollectionCancelDialog,
    ListAttachmentDialog,
  },
  data() {
    return {
      showCopyTooltipMap: {},
      invalidFilters: true,
      filters: null,
      examCollections: [],
      examCollectionHeaders: [
        {
          text: 'Data e Hora',
          value: 'appointment.date',
        },
        {
          text: 'Status',
          value: 'appointment.status',
          sortable: false,
        },
        {
          text: 'Paciente',
          value: 'patient.name',
        },
        {
          text: 'Número da carteirinha',
          value: 'patient.healthInsurancePlanNumber',
        },
        {
          text: 'Arquivo',
          value: 'examRequestAttachmentIds',
          sortable: false,
        },
        {
          text: 'Executante',
          value: 'professional.name',
        },
        {
          text: 'Observações',
          value: 'appointment.notes',
        },
        {
          text: '',
          value: 'actions',
        },
      ],
      showListAttachmentDialog: false,
      selectedAttachmentsData: {
        attachments: [],
        patient: null,
      },
      updateDialogData: {
        showDialog: false,
        title: null,
        fieldNames: [],
        examCollectionData: null,
      },
      cancelDialogData: {
        showDialog: false,
        examCollectionData: null,
      },
    }
  },
  computed: {
    ...mapGetters({
      loading: 'examCollection/loading',
      emrAttachments: 'emr/attachments',
    }),
    isLoading() {
      return this.loading
    },
  },
  methods: {
    ...mapActions({
      listExamCollections: 'examCollection/listExamCollections',
      listAttachments: 'emr/listAttachments',
    }),
    setShowCopyTooltip(tooltipId) {
      this.$set(this.showCopyTooltipMap, tooltipId, true)
      setTimeout(() => {
        this.$set(this.showCopyTooltipMap, tooltipId, false)
      }, 1500)
    },
    async copyToClipboard(content, itemId) {
      await navigator.clipboard.writeText(content)

      this.setShowCopyTooltip(itemId)
    },
    toggleShowCancelAppointmentDialog() {
      this.cancelDialogData.showDialog = !this.cancelDialogData.showDialog
    },
    setAndOpenCancelAppointmentDialog(item) {
      this.cancelDialogData.examCollectionData = item
      this.toggleShowCancelAppointmentDialog()
    },
    toggleShowUpdateDialog() {
      this.updateDialogData.showDialog = !this.updateDialogData.showDialog
    },
    setAndOpenEditDialog(item, type) {
      const dialogConfig = {
        assign: {
          title: 'Definir executante',
          fieldNames: ['professional'],
        },
        edit: {
          title: 'Editar agendamento',
          fieldNames: ['professional', 'status', 'notes'],
        },
      }

      const { title, fieldNames } = dialogConfig[type] || {}

      this.updateDialogData = {
        ...this.updateDialogData,
        title,
        fieldNames,
        examCollectionData: item,
      }

      this.toggleShowUpdateDialog()
    },
    toggleShowListAttachmentDialog() {
      this.showListAttachmentDialog = !this.showListAttachmentDialog
    },
    async openAttachmentsDialog(item) {
      await this.listAttachments({ ids: item.examRequestAttachmentIds })
      this.selectedAttachmentsData.attachments = this.emrAttachments
      this.selectedAttachmentsData.patient = item.patient
      this.toggleShowListAttachmentDialog()
    },
    async attachmentsUpdateData() {
      await this.listAttachments({ ids: pluck('id', this.selectedAttachmentsData.attachments) })
      this.selectedAttachmentsData.attachments = this.emrAttachments
    },
    formatStatus(status) {
      return APPOINTMENT.status[status].label
    },
    formatBiologicalSex(biologicalSex) {
      return biologicalSex === 'M' ? 'Masc' : 'Fem'
    },
    isActionDisabled(item) {
      return item.appointment.status === APPOINTMENT.status.unchecked.value
    },
    async handleFiltersUpdate({ filters, isInvalid }) {
      this.invalidFilters = isInvalid
      if (this.invalidFilters) return

      this.filters = filters
      this.examCollections = await this.listExamCollections(filters)
    },
    async handleExamCollectionUpdate() {
      this.handleFiltersUpdate({ filters: this.filters, isInvalid: false })
    },
    rescheduleAppointment({ patient, appointment }) {
      this.$router.push({
        path: `/patient/${patient.id}`,
        query: {
          openSmartAgenda: true,
          rescheduleAppointmentId: appointment.id,
        },
      })
    },
  },
}
</script>

<style lang="stylus" scoped>

.exam-collection-table >>> thead > tr
  background-color #3233380D !important
  box-shadow 0px 3px 1px 0px #E0E0E0E5 !important
  font-size 0.9rem !important

.exam-collection-table >>> thead > tr > th
  color #707372 !important
  font-size 0.9rem !important
  font-weight 500 !important
  padding 16px !important

.exam-collection-table >>> tbody > tr
  box-shadow 0px 0.5px 2px 0px #DBDBDB

.exam-collection-table >>> tbody > tr > td
  padding 16px !important

</style>
